// @flow

import React from 'react';
import {Row} from 'jsxstyle';

import Button from '@components/Button';
import Image from '@components/Image';
import SectionTitle from '@components/SectionTitle';
import {BLUE, ORANGE} from '@styles/colors';

import type {Node, Element} from 'react';
import type {Props as ButtonProps} from '@components/Button';

type Props = {
  eyebrow?: string,
  headline?: string,
  background?: string,
  alignItems?: string,
  dividerColor?: string,
  copy?: string,
  imageLeft?: boolean,
  textAlign?: string,
  children?: Node,
  button?: ButtonProps,
  fluid?: Object,
  imageSrc?: string,
  imgMaxHeight?: string,
  imgMaxWidth?: string,
  margin?: string,
  sectionCopyWidth?: string,
  comingSoon?: bool,
  objectFit?: string,
  objectPosition?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  eyebrow,
  imageLeft,
  headline,
  margin,
  background,
  alignItems,
  copy,
  textAlign,
  children,
  button,
  dividerColor,
  fluid,
  imageSrc,
  imgMaxHeight,
  sectionCopyWidth,
  imgMaxWidth,
  comingSoon,
  objectFit,
  objectPosition,
}: Props): Element<*> => (
  <Row
    flexDirection={imageLeft ? 'row-reverse' : 'row'}
    margin={comingSoon ? '40px 0px' : (margin ? margin : '40px auto')}
    justifyContent="space-between"
    alignSelf="center"
    alignItems={alignItems ? alignItems : 'center'}
    maxWidth={comingSoon ? '100vw' : '1360px'}
    mediaQueries={mediaQueries}
    background={background}
    // jsxstyle has issue with setting media query property if the original top level property is set via props.
    // will need to check further if we can avoid using important here to override styles
    smFlexDirection={'column-reverse !important'}
    smMargin="0"
  >
    <SectionTitle
      width={sectionCopyWidth}
      lgPadding="0 0 20px 0"
      smPadding="0"
      eyebrow={eyebrow}
      headline={headline}
      comingSoon={comingSoon}
      copy={copy}
      dangerousCopy
      alignItems="flex-start"
      textAlign={textAlign ? textAlign : 'center'}
      button={
        button && !comingSoon && button.buttonLink ? (
          <div>
            <Button
              buttonLink={button.buttonLink}
              buttonText={button.buttonText ? button.buttonText : 'More About Us'}
              buttonColor={BLUE}
            />
          </div>
          
        ) : null
      }
      dividerColor={dividerColor ? dividerColor : ORANGE}
    >
      {children}
    </SectionTitle>
    <Image
      imageSrc={imageSrc}
      fluid={fluid}
      alt={headline}
      imgMaxHeight={imgMaxHeight}
      imgMaxWidth={imgMaxWidth}
      smMaxWidth="100%"
      mediaQueries={mediaQueries}
      smWidth="100%"
      lgWidth={comingSoon ? "50vw" : "auto"}
      width="auto"
      style={{
        padding: '0',
        maxWidth: imgMaxWidth,
        backgroundPosition: 'bottom'
      }}
      imgStyle={{
        width: 'auto',
        height: 'auto',
        position: 'relative',
      }}
      objectFit={comingSoon ? objectFit : null}
      objectPosition={comingSoon ? objectPosition : null}
    />
  </Row>
);
