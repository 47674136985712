import React, {Fragment, useEffect} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import {Block} from 'jsxstyle';

import Section from '@components/layout/Section';
import ImageCallout from '@components/ImageCallout';
import Layout from '@layouts';
import Hero from '@components/Hero';
import PreFooter from '@components/PreFooter';
import ProjectsBar from '@components/ProjectsBar';
import {stripTagsFromWagtail} from '@util';

import {SCHEMA_MAIN} from '@util/googleSchema';

export default ({data, location}: Object) => {
  const {page} = data.wagtail;
  
  useEffect(() => {
    if(location.hash != ''){
      const first_button_type = document.getElementsByClassName(location.hash.replace('#', ''))[0];
      if (first_button_type) {
        first_button_type.click();
      }  
    }
  }, [location.hash]);

  const projectNames = page.projects.map(project => (
    {
      headline: project.headline, 
      comingSoon: project.comingSoon
    }));
  const {
    seoTitle,
    searchDescription,
    heroHeadline,
    heroCopy,
    heroImage,
    projects,
  } = page;
  const firstSectionRef = React.createRef();
  const refs = projects.map(project => React.createRef(project));

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
        <script type="application/ld+json">
          {JSON.stringify(SCHEMA_MAIN)}
        </script>
      </Helmet>
      <Layout>
        <Hero
          title={heroHeadline}
          background={`linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${
            heroImage.url
          })`}
          body={stripTagsFromWagtail(heroCopy)}
          scrollToLink
          copy={'Learn More'}
          sectionRef={firstSectionRef}
          noOffer
        />
        <ProjectsBar
          projectNames={projectNames}
          refs={refs}
          sectionRef={firstSectionRef}
        />
        {projects &&
          projects.map(
            ({eyebrow, headline, copy, image, ctaText, ctaLink, comingSoon}, index) => (
              <Block
                backgroundColor={comingSoon ? "#F6F6F6" : ''}
                >
              <Section key={headline} sectionRef={refs[index]}>
                <ImageCallout
                  headline={headline}
                  eyebrow={eyebrow}
                  comingSoon={comingSoon}
                  copy={stripTagsFromWagtail(copy)}
                  alignItems="flex-start"
                  imageLeft={index % 2 === 0 ? false : true}
                  imageSrc={image && image.url}
                  imgMaxHeight="600px"
                  textAlign="left"
                  dangerousCopy
                  button={
                    !['The Victory', '345 E 94'].includes(headline) ? {
                      buttonLink: ctaLink ? `${ctaLink}` : null,
                      buttonText: ctaText ? `${ctaText}` : null,
                    } : null
                  }                  
                  objectFit="cover"
                  objectPosition="center bottom"
                />
              </Section>
              </Block>
            ),
          )}
        <PreFooter
          headline="Interested in becoming a resident?"
          copy="Get in touch with us to find your perfect Fetner apartment."
        />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment ProjectsFragment on Wagtail_ProjectsPage {
    seoTitle
    searchDescription
    heroHeadline
    heroCopy
    heroImage {
      url
    }
    heroCtaText
    heroCtaLink
    heroOfferHeadline
    heroOfferCopy
    projects {
      eyebrow
      headline
      copy
      image {
        url
      }
      ctaText
      ctaLink
      comingSoon
    }
  }
  query {
    wagtail {
      page(slug: "projects") {
        ...ProjectsFragment
      }
    }
  }
`;
