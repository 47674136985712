// @flow

import React, {Component} from 'react';
import {Col} from 'jsxstyle';
import Image from '@components/Image';
import {ORANGE, BLUE, TRANSPARENT} from '@styles/colors';

type Props = {
  neighborhood?: {
    id: number,
    name: string,
  },
  handleClick: () => void,
  selected: boolean,
  projectName?: string,
  className?: string,
};

type State = {
  hovered: boolean,
};

export default class FilterButton extends Component<Props, State> {
  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const {neighborhood, projectName, handleClick, selected, icon, className} = this.props;
    return (
      <Col
        component="button"
        props={{
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
          onClick: () => handleClick(),
        }}
        background={TRANSPARENT}
        border="none"
        height="20px"
        width={`auto`}
        margin="0 5px"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        fontSize="16px"
        fontFamily="proxima-nova"
        cursor="pointer"
        color={selected || this.state.hovered ? ORANGE : BLUE}
        flexDirection="row"
        className={className ? className : ''}
      >
        {icon ? (
        <Image
          verticalAlign={'middle'}
          imgMaxHeight={'15px'}
          imgMaxWidth={'15px'}
          imageSrc={icon}
          margin={'2px'}
        />) : null }
        {neighborhood ? neighborhood.name : projectName}
      </Col>
    );
  }
}
