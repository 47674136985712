// @flow

import React, {useState, Fragment, useEffect} from 'react';

import {Row, Col, Block} from 'jsxstyle';

import WindowsizeMonitor from './WindowSizeMonitor';

import Dropdown from '@components/Form/Dropdown';
import FilterButton from '@components/Search/FilterButton';
import Eyebrow from '@components/Eyebrow';

import dropdownArrow from '@data/svg/DropdownArrow.svg';
import warningIcon from '@data/svg/WarningOrange.svg';

import {ORANGE, LIGHT_GREY, TRANSPARENT} from '@styles/colors';

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

const condoProperties = [
  '1212 Fifth Avenue',
  '1280 Fifth Avenue',
  '90 East End Avenue',
  '10101 Grosvenor',
];

const rentalProperties = [
  'The Epic',
  'The Victory',
  '345 E 94',
  'EŌS',
  'Via 57 West',
  '1214 Fifth Avenue',
];

const comingSoonProperties = [
  '266 West 96th Street',
  'The Bold',
  'The Italic',
]

const SMALL_SCREEN_WIDTH = 1025;

function ProjectsBar (props) {
  const {projectNames} = props;

  const initialSortedCondoProperties = [];
  const initialSortedRentalProperties = [];
  const initialSortedComingSoonProperties = [];

  projectNames.map(project => {
    if (condoProperties.includes(project.headline)) {
      return initialSortedCondoProperties.push(project);
    } else if (rentalProperties.includes(project.headline)) {
      return initialSortedRentalProperties.push(project);
    } else if (comingSoonProperties.includes(project.headline)) {
      return initialSortedComingSoonProperties.push(project);
    } else {
      return null;
    }
  });

  const [selectedProject, setSelectedProject] = useState(null);
  const [sortedCondoProperties, setSortedCondoProperties] = useState(initialSortedCondoProperties);
  const [sortedRentalProperties, setSortedRentalProperties] = useState(initialSortedRentalProperties);
  const [sortedComingSoonProperties, setSortedComingSoonProperties] = useState(initialSortedComingSoonProperties);

  const handleClick = (projectName: string, sectionRef) => {
    setSelectedProject(projectName);
    return (
      sectionRef &&
      // $FlowFixMe
      sectionRef.current &&
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    );
  };

  const handleChange = (e: Event, sectionRef) => {
    setSelectedProject(e.target.value);
    return (
      sectionRef &&
      // $FlowFixMe
      sectionRef.current &&
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    );
  };

  const {sectionRef, refs} = props;
  
  const getIndex = function (arr, name) {
    return arr.indexOf(name);
  }
  return (
    <Col
      width="calc(100% - 40px)"
      height="auto"
      justifyContent="space-between"
      alignItems="center"
      padding="40px 20px"
      margin="0 auto"
      background={LIGHT_GREY}
      mediaQueries={mediaQueries}
      smPadding="20px 0"
      smWidth="100%"
      fontFamily="proxima-nova"
      props={{
        ref: sectionRef,
      }}
    >
      <WindowsizeMonitor>
        {({width}) => {
          if (width >= SMALL_SCREEN_WIDTH) {
            return (
              <Fragment>
                <Row alignItems="center" width="100%" marginBottom="20px">
                  <Eyebrow
                    color={ORANGE}
                    whiteSpace="nowrap"
                    margin="0 10px 0 0"
                  >
                    {'rental properties'}
                  </Eyebrow>
                  <Row width="100%" height="auto" flexWrap="wrap">
                    {sortedRentalProperties.map(
                      (projectName, index) => {
                        return (
                          <FilterButton
                            key={projectName.headline}
                            className="rental-properties"
                            handleClick={() =>
                              handleClick(projectName.headline, refs[index])
                            }
                            selected={
                              projectName.headline === selectedProject
                                ? true
                                : false
                            }
                            projectName={projectName.headline}
                            icon={projectName.comingSoon ? warningIcon : false}
                          />
                        );
                      },
                    )}
                  </Row>
                </Row>
                <Row alignItems="center" width="100%" marginBottom="20px">
                  <Eyebrow
                    color={ORANGE}
                    whiteSpace="nowrap"
                    margin="0 10px 0 0"
                  >
                    {'coming soon'}
                  </Eyebrow>
                  <Row width="100%" height="auto" flexWrap="wrap">
                    {sortedComingSoonProperties.map(
                      (projectName, index) => {
                        return (
                          <FilterButton
                            key={projectName.headline}
                            className="coming-soon"
                            handleClick={() =>
                              handleClick(projectName.headline, refs[index + 6])
                            }
                            selected={
                              projectName.headline === selectedProject
                                ? true
                                : false
                            }
                            projectName={projectName.headline}
                            icon={projectName.comingSoon ? warningIcon : false}
                          />
                        );
                      },
                    )}
                  </Row>
                </Row>
                <Row alignItems="center" width="100%">
                  <Eyebrow
                    color={ORANGE}
                    whiteSpace="nowrap"
                    margin="0 10px 0 0"
                  >
                    {'condo properties'}
                  </Eyebrow>
                  <Row width="100%" height="auto" flexWrap="wrap">
                    {sortedCondoProperties.map(
                      (projectName, index) => {
                        return (
                          <FilterButton
                            key={projectName.headline}
                            className="condo-properties"
                            handleClick={() =>
                              handleClick(projectName.headline, refs[index + 9])
                            }
                            selected={
                              projectName.headline === selectedProject
                                ? true
                                : false
                            }
                            projectName={projectName.headline}
                          />
                        );
                      },
                    )}
                  </Row>
                </Row>
              </Fragment>
            );
          } else {
            return (
              <Col
                width="80%"
                margin="0 auto"
                justifyContent="center"
                alignItems="center"
              >
                <Dropdown
                  width="80%"
                  backgroundColor={TRANSPARENT}
                  selectedValue={selectedProject}
                  handleChange={e =>
                    handleChange(
                      e,
                      refs[
                        getIndex(
                          sortedRentalProperties.map(rentalProperty => rentalProperty.headline),
                          e.target.value,
                        )
                      ],
                    )
                  }
                  arrow={dropdownArrow}
                  defaultValue="Rental Properties"
                  options={sortedRentalProperties.map(rentalProperty => rentalProperty.headline)}
                />
                <Dropdown
                  width="80%"
                  backgroundColor={TRANSPARENT}
                  selectedValue={selectedProject}
                  handleChange={e =>
                    handleChange(
                      e,
                      refs[
                        getIndex(
                          sortedComingSoonProperties.map(comingSoonProperty => comingSoonProperty.headline),
                          e.target.value,
                        ) + 6
                      ],
                    )
                  }
                  arrow={dropdownArrow}
                  defaultValue="Coming Soon"
                  options={sortedComingSoonProperties.map(comingSoonProperty => comingSoonProperty.headline)}
                />
                <Dropdown
                  width="80%"
                  backgroundColor={TRANSPARENT}
                  selectedValue={selectedProject}
                  handleChange={e =>
                    handleChange(
                      e,
                      refs[
                        getIndex(
                          sortedCondoProperties.map(condoProperty => condoProperty.headline),
                          e.target.value,
                        ) + 9
                      ],
                    )
                  }
                  arrow={dropdownArrow}
                  defaultValue="Condo Properties"
                  options={sortedCondoProperties.map(condoProperty => condoProperty.headline)}
                />
              </Col>
            );
          }
        }}
      </WindowsizeMonitor>
    </Col>
  );
}
export default ProjectsBar